import React from "react";
import IconFacebook from "../svgs/IconFacebook.svg";
import IconInstagram from "../svgs/IconInstagram.svg";
import IconTiktok from "../svgs/IconTiktok.svg";

export function SocialIcons() {
  return (
    <div className="social_icons">
      <a
        href="https://www.facebook.com/StageOneEvents/events"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconFacebook />
      </a>
      <a
        href="https://www.instagram.com/stageoneevents_newcastle"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconInstagram />
      </a>
      <a
        href="https://www.tiktok.com/@stageoneevents"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconTiktok />
      </a>
    </div>
  );
}
