import { SocialIcons } from "components/SocialIcons";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Footer() {
  return (
    <footer className="footer">
      <div>
        <StaticImage
          className="footer__logo"
          src="../images/logo.png"
          alt="Stage One logo"
        />
        <SocialIcons />
        <div className="footer__contact">
          <b>Contact us</b>
          <a className="animated_link" href="tel:+447516 679921">
            +447516 679921
          </a>
        </div>
      </div>
    </footer>
  );
}
