import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useEventListener } from "hooks/useEventListener";
import { useFocusOut } from "hooks/useFocusOut";
import React, { useEffect, useRef, useState } from "react";
import IconCross from "../svgs/IconCross.svg";
import IconMenu from "../svgs/IconMenu.svg";

const links = [
  { to: "/", anchor: "hero", children: "Home" },
  {
    to: "#tickets",
    anchor: "tickets",
    children: "Buy Tickets",
    className: "yellow",
  },
  {
    to: "#fight",
    anchor: "fight",
    children: "Sign Up to Fight",
    className: "red",
  },
  { to: "#reps", anchor: "reps", children: "Make ££" },
  { to: "#gallery", anchor: "gallery", children: "Gallery" },
  { to: "#contact", anchor: "contact", children: "Contact" },
];

export function Header() {
  const [navIsOpen, setNavIsOpen] = useState(false);

  const headerRef = useRef(null);
  useFocusOut(headerRef, () => setNavIsOpen(false));

  useEventListener("keydown", (e) => {
    if (e.key === "Escape") setNavIsOpen(false);
  });

  const [isTop, setIsTop] = useState(true);
  const updateIsTop = () => setIsTop(window.scrollY <= 0);
  useEffect(updateIsTop, []);
  useEventListener("scroll", updateIsTop, { passive: true });

  const [currentAnchor, setCurrentAnchor] = useState("hero");
  const { current: anchorHeights } = useRef({});
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(
          (entry) =>
            (anchorHeights[entry.target.id] =
              entry.intersectionRatio === 1
                ? Infinity
                : entry.intersectionRect.height)
        );

        const heights = { ...anchorHeights };
        heights["contact"] += heights["partners"];
        delete heights["partners"];

        setCurrentAnchor(
          Object.keys(heights).sort((a, b) => heights[b] - heights[a])[0]
        );
      },
      { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
    );

    document
      .querySelectorAll("main > section")
      .forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [anchorHeights]);

  return (
    <header ref={headerRef} id="header" data-open={navIsOpen} data-top={isTop}>
      <div className="header__content">
        <div className="header__logo-primary">
          <Link to="/" tabIndex={-1}>
            <StaticImage
              className="header__image"
              src="../images/logo.png"
              alt="Stage One logo"
            />
          </Link>
        </div>
        <div className="header__logo-secondary">
          <Link to="/" tabIndex={-1} onClick={() => setNavIsOpen(false)}>
            <StaticImage
              className="header__image"
              src="../images/logo.png"
              alt="Stage One logo"
            />
          </Link>
        </div>
        <nav className="header__nav-desktop">
          {links.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className={link.className}
              aria-current={currentAnchor === link.anchor ? true : null}
            >
              {link.children}
            </Link>
          ))}
        </nav>
        <button
          className="header__toggle_nav"
          onClick={() => setNavIsOpen(!navIsOpen)}
          aria-expanded={navIsOpen}
          aria-controls="header"
        >
          {navIsOpen ? <IconCross /> : <IconMenu />}
        </button>
      </div>
      <nav className="header__nav-mobile">
        {links.map((link) => (
          <Link
            key={link.to}
            to={link.to}
            tabIndex={navIsOpen ? 0 : -1}
            onClick={() => setNavIsOpen(false)}
            aria-current={currentAnchor === link.anchor ? true : null}
          >
            {link.children}
          </Link>
        ))}
      </nav>
    </header>
  );
}
